
import { Component, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { Menu } from '@gsk-tech/gsk-menu/gsk-menu';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import GButton from '@/components/gsk-components/GskButton.vue';
import NavigationTabs from '@/components/NavigationTabs.vue';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import MaxWidth from '@/components/MaxWidth.vue';
import UserList from '@/components/UserList.vue';
import { GroupedNavigationItem, UINavigationItem } from '@/types';
import { Platforms, RouteNames } from '@/constants';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';
import { addAnalyticsRouteParams, ClickData } from '@/analytics';
import GAnalytics from '@/components/GAnalytics';
import HelpLink from '@/components/HelpLink.vue';
import NavigationList from '@/components/NavigationList.vue';
import ExpandableNavigationList from '@/components/ExpandableNavigationList.vue';
import { FeatureFlagsModule } from '@/store/modules/feature-flags.module';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import { BaseUser } from '@/types/users.types';
import { EnumsModule } from '@/store/modules/enums.module';
import { ProjectEnvironment } from '@/types/projects.types';
import last from 'lodash/last';
import { Environment, EnvironmentType, IEnvironmentMnemonic } from '@/types/enum.types';
import { openErrorSnackbar } from '@/utils/components';
import { AxiosError } from 'axios';
import CopyCode from '@/components/CopyCode.vue';
import PlatformTypeChip from '@/components/PlatformTypeChip.vue';
type GskMenu = Menu;

enum parts {
  AUTH = 'auth',
  SERVICES = 'services',
  REGISTRATIONS = 'registrations',
  RPA = 'rpa',
  STATUS = 'status',
}

@Component({
  components: {
    ExpandableNavigationList,
    GButton,
    NavigationTabs,
    MaxWidth,
    UserList,
    DeleteDialog,
    GAnalytics,
    HelpLink,
    NavigationList,
    Grid,
    GridCell,
    CopyCode,
    PlatformTypeChip,
  },
  async beforeRouteEnter(to, from, next) {
    // If navigated directly to the any child route(through page refresh),
    // or navigated back after page refresh in the bot catalog page.
    if (!from.name || from.name === RouteNames.ListingSection) {
      ProjectDetailsModule.setEmptyProject();
      try {
        await ProjectDetailsModule.getProject(to.params.id);
      } catch(e) {
        next({
          name: RouteNames.NotFound,
          params: {
            errMsg: (e as AxiosError)?.response?.data?.error
          }
        });
        return;
      }
    }
    if (to.name === RouteNames.ProjectCiCd) {
      next();
      return;
    }

    if (!to.params.env) {
      // if there is no env param, user has navigated directly to project and we need
      // to redirect to an env route
      ProjectDetailsModule.setEmptyProject();
      try {
        await ProjectDetailsModule.getProject(to.params.id);
      } catch(e) {
        next({
          name: RouteNames.NotFound,
          params: {
            errMsg: (e as AxiosError)?.response?.data?.error
          }
        })
        return;
      }
      const env = ProjectDetailsModule.defaultEnv;
      if (env) {
        let params: Record<string, string> = {};
        let name: string;
        if (env.environmentTypeMnemonic === EnvironmentType.RPA) {
          name = RouteNames.ProjectRpa;
          params = {
            id: to.params.id,
            env: env.mnemonic,
          };
        } else {
          name = RouteNames.ProjectEnvDetails;
          params = {
            id: to.params.id,
            oAuthMneumonicType: to.params.oAuthSelected,
            env: env.projectEnvironmentId.toString(),
            section: 'auth',
          };
        }

        next({
          name,
          params: addAnalyticsRouteParams(params, {
            projectName: ProjectDetailsModule.projectDetails.projectName,
            projectId: ProjectDetailsModule.projectDetails.projectId,
          }),
        });
      }
    }
    next();
  },
})
export default class ProjectDetailView extends Vue {
  private openDelete = false;
  public windowWidth: any = window.innerWidth;

  get deleteAnalytics(): ClickData {
    return {
      clickTarget: 'delete-project-menu-option',
      projectName: this.projectDetails.projectName,
      projectId: this.projectDetails.projectId,
    };
  }

  get confirmDeleteAnalytics(): ClickData {
    return {
      clickTarget: 'delete-project-confirm',
      projectName: this.projectDetails.projectName,
      projectId: this.projectDetails.projectId,
    };
  }

  get tooltipPosition() {
    return this.windowWidth <= 1440? 'after': 'below';
  }

  onResize() {
    this.windowWidth = window.innerWidth;
  }

  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  }

  showMenu() {
    const menu = this.$refs.settingsMenu as GskMenu;
    menu.setAnchorCorner(menu.Corner.BOTTOM_START);
    menu.setAnchorElement(this.$refs.menuContainer as Element);
    menu.setAnchorMargin({ bottom: 16 });
    menu.open = !menu.open;
  }

  created() {
    const params = this.$route.params;
    ProjectDetailsModule.setOAuthMneumonic({
      projectId: +params.id, 
      projectEnvId: +params.env, 
      oAuthType: params.oAuthMneumonicType
    })
  }

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  }

  async confirmDelete() {
    const projectId = +this.$route.params.id;
    try { 
        await ProjectDetailsModule.deleteProject(projectId);
        this.$router.replace({ name: RouteNames.ProjectsList }).catch(this.$logger.error.bind(this));
      } catch(error) {
        openErrorSnackbar.call(this, (error as unknown as string) || 'Error: Failed to delete project.')
      }
  }

  get headerStyle() {
    return {
      backgroundColor: 'var(--theme-lightest)',
    };
  }

  get projectDetails() {
    return ProjectDetailsModule.projectDetails;
  }

  get isProjectViewerRole() {
    const { currentUserRoleId } = ProjectDetailsModule.projectDetails;
    return EnumsModule.enums.role.PROJECTVIEWER.id === currentUserRoleId;
  }

  get projectUsers(): BaseUser[] {
    const { projectUsers, teams } = ProjectDetailsModule.projectDetails;
    const teamMembers = teams.flatMap(team => team.teamMembers);
    return uniqBy(projectUsers.concat(teamMembers), user => user.email);
  }

  get environments() {
    return ProjectDetailsModule.projectDetails.environments || [];
  }

  get rpaEnvironments(): ProjectEnvironment[] {
    return ProjectDetailsModule.rpaEnvs;
  }

  get rpaProjectEnvId(): IEnvironmentMnemonic {
    if (this.$route.name === RouteNames.ProjectRpa) {
      return this.$route.params.env as IEnvironmentMnemonic;
    }
    // should link us to the highest env, KRAK-4007

    const refEnv = last(this.rpaEnvironments);
    return refEnv ? refEnv.mnemonic : Environment.RPA_DEV;
  }

  getSectionRoute(env: string, section: string): UINavigationItem['route'] {
    return {
      name: RouteNames.ProjectEnvDetails,
      params: {
        id: this.projectDetails.projectId.toString(),
        env,
        section,
      },
    };
  }

  getSectionLinks(envMnemonic: string): UINavigationItem[] {
    let links: UINavigationItem[] = [];
    const envs = this.environments.filter(env => env.environmentTypeMnemonic === envMnemonic);
    const currentEnv = envs.find(
      env => env.projectEnvironmentId.toString() === this.$route.params.env,
    );
    let penvId: string = envs[0]?.projectEnvironmentId?.toString();
    if (currentEnv) {
      penvId = currentEnv.projectEnvironmentId.toString();
    }

    if (envMnemonic === 'API') {
      links = [
        {
          key: parts.AUTH,
          route: this.getSectionRoute(penvId, parts.AUTH),
          exact: true,
          text: 'Authentication',
        },
        {
          key: parts.SERVICES,
          exact: true,
          route: this.getSectionRoute(penvId, parts.SERVICES),
          text: 'Connected Services',
        },
      ];
        links.push({
          key: parts.REGISTRATIONS,
          exact: true,
          route: this.getSectionRoute(penvId, parts.REGISTRATIONS),
          text: 'API Registrations',
        });
    }
    return links;
  }

  get leftNav(): GroupedNavigationItem[] {
    const envTypes = EnumsModule.enumsLists.environmentType.filter(et => {
      if (et.mnemonic === 'RPA') {
        return FeatureFlagsModule.featureFlags.BOTPROMOTION.featureFlagEnabled;
      }
      if (et.mnemonic === 'CICD') {
        return FeatureFlagsModule.featureFlags.CICD.featureFlagEnabled;
      }
      return true;
    });
    return sortBy(envTypes, ['sequenceNumber']).map((env): GroupedNavigationItem => {
      if (['CICD'].includes(env.mnemonic)) {
        return {
          text: env.description,
          key: env.mnemonic,
          route: {
            name: `project-env-${env.mnemonic}`,
            params: {
              id: this.projectDetails.projectId.toString(),
              env: this.environments[0]?.projectEnvironmentId?.toString(),
            },
          },
        };
      }
      if (['RPA'].includes(env.mnemonic)) {
        return {
          text: env.description,
          key: env.mnemonic,
          route: {
            name: `project-env-${env.mnemonic}`,
            params: {
              id: this.projectDetails.projectId.toString(),
              env: this.rpaProjectEnvId,
            },
          },
        };
      }
      return {
        text: env.description,
        key: env.id.toString(),
        disabled: false,
        // disabled: env.projectEnvironmentId === -1,
        children: this.getSectionLinks(env.mnemonic),
      };
    });
  }

  get permissionsLink(): Location {
    return {
      name: RouteNames.ProjectPermissions,
      params: {
        id: this.$route.params.id,
      },
    };
  }
  get settingsLink(): Location {
    return {
      name: RouteNames.ProjectSettings,
      params: {
        id: this.$route.params.id,
      },
    };
  }
  get projectLink () { 
    const path = `${'projects/'+ this.$route.params.id}`;
    return new URL(path,location.origin).href;    
  }

  get platform() {
    const mnemonic =  ProjectDetailsModule.projectDetails.platform?.mnemonic;
    return Platforms[mnemonic].readableName;
  }
}
