
import { Component, Prop, Vue } from 'vue-property-decorator';
import { HELP_ROOT_PATH } from '@/constants/help.constants';
import { sanitize } from '@/utils/components';

@Component
export default class HelpLink extends Vue {
  @Prop({ type: String, default: '' }) docPath!: string;
  @Prop({ type: String, default: 'question' }) icon!: string;

  get encodedLink() {
    return sanitize(encodeURI(`/${HELP_ROOT_PATH}${this.docPath}`));
  }
}
